/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";

// page components
import SchedulingAppointements from "./SchedulingAppointements";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFooter() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div>
        <Footer
          content={
            <div className={classNames(classes.pullCenter, classes.copyRight)}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a href="https://www.bucknerprosthetics.com/">
                Buckner Prosthetics &amp; Orthotics
              </a>{" "}
              All Rights Reserved. <a href="/privacy-policy">Privacy Policy</a>
            </div>
          }
        >
          <div className={classes.footer}>
            <GridContainer>
              <GridItem xs={12} sm={3} md={3}>
                <h5>About Us</h5>
                <ul className={classes.linksVertical}>
                  <li>
                    <a href="/about">About Buckner</a>
                  </li>
                  <li>
                    <a href="/locations">Locations</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <h5>Patients</h5>
                <ul className={classes.linksVertical}>
                  <li>
                    <Link to="/patient-services">Patient Services</Link>
                  </li>
                  <li>
                    <a href="/referrals">Referrals</a>
                  </li>
                  <li>
                    <a href="/prosthetic-supply">Prosthetic Supplies</a>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <SchedulingAppointements />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h5>Follow Us</h5>
                <ul className={classes.socialButtons}>
                  <li>
                    <Button
                      justIcon
                      simple
                      href="https://www.google.com/search?q=buckner+prosthetics"
                      color="google"
                    >
                      <i className="fab fa-google" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      justIcon
                      simple
                      href="https://www.facebook.com/Buckner-Prosthetic-Orthotic-Laboratories-160251837329551/?ref=bucknerprosthetics"
                      color="facebook"
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      justIcon
                      simple
                      href="https://www.instagram.com/buckner_prosthetics/?ref=bucknerprosthetics"
                      color="instagram"
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      justIcon
                      simple
                      href="https://www.youtube.com/channel/UC0RMpUqgTiTPUUYRc-X6KYw?ref=bucknerprosthetics"
                      color="youtube"
                    >
                      <i className="fab fa-youtube" />
                    </Button>
                  </li>
                </ul>
              </GridItem>
            </GridContainer>
          </div>
        </Footer>
      </div>
    </div>
  );
}
