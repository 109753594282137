import React from "react";
// core components
import Button from "components/CustomButtons/Button.js";
import allLocations from "allLocations";

const SchedulingAppointements = () => {
  return (
    <>
      <h5>Scheduling Appointments</h5>
      <ul>
        {allLocations.map((location) => (
          <li key={location.key}>
            <h6>{location.name}</h6>
            <Button color="info" simple href={`tel:+1 ${location.phone}`}>
              <i className="fas fa-phone" /> {location.phone}
            </Button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SchedulingAppointements;
