import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  checkGoogleReCaptchaInjected,
  hideGoogleReCaptchaBadge,
  injectGoogleReCaptchaScript,
  removeGoogleReCaptchaBadge,
  removeGoogleReCaptchaContainer,
  removeGoogleReCaptchaScript,
} from "@google-recaptcha/core";

import { GoogleReCaptchaContextProvider } from "./GoogleReCaptchaContext";

const onLoadCallbackName = "onGoogleReCaptchaLoad";
const containerId = "google-recaptcha-container";
class Badges {
  static _INLINE = "inline";
  static _BL = "bottomleft";
  static _BR = "bottomright";
  static _HIDDEN = "hidden";

  static get Inline() {
    return this._INLINE;
  }
  static get BottomLeft() {
    return this._BL;
  }
  static get BottomRight() {
    return this._BR;
  }
  static get Hidden() {
    return this._HIDDEN;
  }
}

/**
 * Renders the Google ReCaptcha component and handles the loading and initialization of the ReCaptcha script.
 */
export const GoogleReCaptchaProvider = ({ type, siteKey, children }) => {
  const language = null;
  const isEnterprise = false;
  const onLoad = () => {
    console.log("ReCaptcha loaded.");
  };
  const onError = (err) => console.error(err);
  const explicit = {
    badge: Badges.Hidden,
  };

  const [isLoading, setIsLoading] = React.useState(true);
  const [
    googleReCaptchaInstance,
    setGoogleReCaptchaInstance,
  ] = React.useState();

  const getExplicit = useCallback(() => {
    return explicit;
  }, [explicit]);

  const resetInstance = useCallback(() => {
    googleReCaptchaInstance && googleReCaptchaInstance.reset();
  }, [googleReCaptchaInstance]);

  React.useEffect(() => {
    if (!siteKey) {
      throw new Error("Google ReCaptcha site key not provided");
    }

    const _explicit = getExplicit();

    const scriptId = "google-recaptcha-script";
    const isGoogleReCaptchaInjected = checkGoogleReCaptchaInjected(scriptId);

    const onload = () => {
      const googleReCaptcha = isEnterprise
        ? window.grecaptcha?.enterprise
        : window.grecaptcha;

      if (!googleReCaptcha) {
        if (onError) onError();
        return;
      }

      if (!_explicit) {
        googleReCaptcha.ready(async () => {
          setGoogleReCaptchaInstance(googleReCaptcha);
          if (onLoad) await onLoad(googleReCaptcha);
          setIsLoading(true);
        });
      }

      if (_explicit) {
        const params = {
          size:
            type === "v3" || type === "v2-invisible" ? "invisible" : "normal",
          ...((type === "v3" || type === "v2-invisible") && {
            badge: "bottomright",
          }),
          sitekey: siteKey,
          ..._explicit,
          "expired-callback": _explicit.expiredCallback,
          "error-callback": _explicit.errorCallback,
        };

        if (!isGoogleReCaptchaInjected) {
          const isV3AndV2OptWidgetHidden =
            (type === "v3" || type === "v2-invisible") &&
            _explicit?.badge === "hidden";

          if (isV3AndV2OptWidgetHidden) {
            hideGoogleReCaptchaBadge();
          }
        }

        googleReCaptcha.ready(async () => {
          if (_explicit.container) {
            googleReCaptcha.render(
              _explicit.container,
              params,
              !!_explicit.inherit
            );
          }

          setGoogleReCaptchaInstance(googleReCaptcha);

          if (onLoad) await onLoad(googleReCaptcha);
          setIsLoading(true);
        });
      }
    };
    window[onLoadCallbackName] = onload;

    if (isGoogleReCaptchaInjected) {
      onload();
    } else {
      injectGoogleReCaptchaScript({
        isEnterprise,
        undefined,
        ...((type === "v3" || type === "v2-invisible") &&
          _explicit?.badge && {
            badge:
              _explicit?.badge === "hidden" ? "bottomright" : _explicit?.badge,
          }),
        ...(language && { hl: language }),
        render:
          ((type === "v3" || type === "v2-invisible") &&
            _explicit?.container) ||
          type === "v2-checkbox"
            ? "explicit"
            : siteKey,
        onload,
        id: scriptId,
      });
    }

    return () => {
      resetInstance();

      if (!isGoogleReCaptchaInjected) removeGoogleReCaptchaScript(scriptId);
      if (
        (type === "v3" || type === "v2-invisible") &&
        !_explicit?.container &&
        _explicit?.badge
      ) {
        removeGoogleReCaptchaContainer(containerId);
      } else {
        removeGoogleReCaptchaBadge();
      }
    };
  }, [siteKey, type, isEnterprise, language, getExplicit, resetInstance]);

  const executeV3 = React.useCallback(
    (action) => {
      if (!googleReCaptchaInstance?.execute) {
        throw new Error("Google ReCaptcha has not been loaded");
      }

      return googleReCaptchaInstance.execute(siteKey, { action });
    },
    [googleReCaptchaInstance, siteKey]
  );

  const validate = (token) => {
    return fetch(`/.netlify/functions/recaptcha?rt=${token}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("response", res);
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const executeV2Invisible = React.useCallback(
    (optWidgetId) => {
      if (!googleReCaptchaInstance?.execute) {
        throw new Error("Google ReCaptcha has not been loaded");
      }

      return googleReCaptchaInstance.execute(optWidgetId);
    },
    [googleReCaptchaInstance]
  );

  const value = React.useMemo(
    () => ({
      googleReCaptcha: googleReCaptchaInstance,
      siteKey,
      isLoading,
      executeV2Invisible,
      executeV3,
      validate,
      reset: googleReCaptchaInstance?.reset,
      getResponse: googleReCaptchaInstance?.getResponse,
      render: googleReCaptchaInstance?.render,
      ...(language && { language }),
    }),
    [
      siteKey,
      googleReCaptchaInstance,
      isLoading,
      language,
      executeV2Invisible,
      executeV3,
    ]
  );

  return (
    <GoogleReCaptchaContextProvider value={value}>
      {children}
    </GoogleReCaptchaContextProvider>
  );
};
GoogleReCaptchaProvider.propTypes = {
  type: PropTypes.oneOf(["v3"]),
  siteKey: PropTypes.string.isRequired,
  children: PropTypes.node,
};
